import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/global.css"

const SecondPage = () => (
  <Layout>
    <SEO title="Pricing" />
    <div className="w3-row pricing-container get-ready">
      <div className="w3-col m12 l12">
        <h1 className="punchline">
          以為俾錢先用得?<br />基本功能我哋為你預備好
        </h1>
        <p className="punchline">
          傳送訊息或文件<span className="spacing">轉載網頁連結</span>
          <span className="spacing">多人參與客戶對話</span><br />
          <span className="spacing">建立客戶簡介</span>
          <span className="spacing">統計數據分析</span>
        </p>
      </div>
    </div>
    <div className="w3-row container">
      <div className="w3-col m12 l12 element">
        <h1 className="punchline">
          還未夠用嗎?<br />你可以考慮購買以下計劃
        </h1>
        <div className="w3-row element pricing-scheme">
          <div className="w3-col m12 l2 card plan-01 scheme">
            <h3>單用戶</h3>
            <div className="change-plan-price">
              <span className="price free">免費</span>
            </div>
            <ul>
              <li>包括 1 個授權使用者</li>
              <li>最多可與 50 個客戶保持聯繫</li>
              <li>支援 Facebook Messenger、Web (SDK) 及 App (SDK)</li>
              <li>傳送訊息或文件</li>
              <li>轉載網頁連結</li>
              <li>多人參與客戶對話</li>
              <li>建立客戶簡介</li>
              <li>統計數據分析</li>
            </ul>
            {/* <button className="button-contact-us">訂閱</button> */}
          </div>
          <div className="w3-col m12 l2 card plan-02 scheme">
            <h3>多用戶</h3>
            <div className="change-plan-price">
              <span className="dollar-sign">$</span>
              <span className="price">800</span>
              <span className="asterisk">*</span> 每月
              </div>
            <ul>
              <li>包括 2 個授權使用者</li>
              <li>最多可與 1000 個客戶保持聯繫</li>
              <li>支援 Facebook Messenger、Web (SDK) 及 App (SDK)</li>
              <li>傳送訊息或文件</li>
              <li>轉載網頁連結</li>
              <li>多人參與客戶對話</li>
              <li>建立客戶簡介</li>
              <li>統計數據分析</li>
              <li>加 $250 可增加 1 個授權使用者</li>
            </ul>
            {/* <button className="button-contact-us">訂閱</button> */}
          </div>
          <div className="w3-col m12 l2 card plan-03 scheme">
            <div className="ribbon"></div>
            <h3>團隊用戶</h3>
            <div className="change-plan-price">
              <span className="dollar-sign">$</span>
              <span className="price">1,960</span>
              <span className="asterisk">*</span> 每月
              </div>
            <ul>
              <li>包括 3 個授權使用者</li>
              <li>最多可與 2000 個客戶保持聯繫</li>
              <li>支援 WhatsApp Business 號碼, Facebook Messenger、Web (SDK) 及 App (SDK)</li>
              <li>傳送訊息或文件</li>
              <li>轉載網頁連結</li>
              <li>多人參與客戶對話</li>
              <li>建立客戶簡介</li>
              <li>統計數據分析</li>
              <li>加 $250 可增加 1 個授權使用者</li>
            </ul>
            {/* <button className="button-contact-us">訂閱</button> */}
          </div>
          <div className="w3-col m12 l2 card plan-04 scheme container">
            <div className="element">
              <h3>企業用戶</h3>
              <p>可以與我們聯絡</p>
              {/* <button className="button-contact-us">與我們聯絡</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default SecondPage
